import Amplitude from 'amplitude-js';
import { isDevelopment } from './HostingEnv';

const AMPLITUDE_SESSION_TIMEOUT = 20 * 60 * 1000;
const ampLogStyle = 'background-color:aqua; color:black;';

const logToConsole = isDevelopment ? console.log : () => {}; // eslint-disable-line no-console

if (typeof window !== 'undefined') {
  Amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    sessionTimeout: AMPLITUDE_SESSION_TIMEOUT,
  });
  Amplitude.getInstance().setVersionName(process.env.NEXT_PUBLIC_VERSION);
}

export const getAmpSessionID = () => Amplitude.getInstance().getSessionId();
export const setAmpUser = (email) => {
  logToConsole(`%c[Amplitude]%c Setting user ID: ${email}`, ampLogStyle, '');
  Amplitude.getInstance().setUserId(email);
};
export const setUserProps = (properties) => {
  logToConsole('%c[Amplitude]%c Setting user property:', ampLogStyle, '', properties);
  Amplitude.getInstance().setUserProperties(properties);
};

export const logAmpEvent = (event, properties = undefined) => {
  logToConsole(
    `Amplitude event: %c${event}`,
    ampLogStyle,
    properties ? 'with properties:' : '',
    properties || ''
  );
  Amplitude.getInstance().logEvent(event, properties);
};

export const events = {
  // navbar
  USER_CLICKED_NAVBAR_LINK: 'USER_CLICKED_NAVBAR_LINK',

  // footer
  USER_CLICKED_FOOTER_LINK: 'USER_CLICKED_FOOTER_LINK',
  USER_CHANGED_LANGUAGE: 'USER_CHANGED_LANGUAGE',

  // auth
  USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK',
  USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_LINK',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_CLICKED_PAYSIMPLY_SERVICES_LINK: 'USER_CLICKED_PAYSIMPLY_SERVICES_LINK',
  // confirm email
  USER_VIEWED_CONFIRM_EMAIL_PAGE: 'USER_VIEWED_CONFIRM_EMAIL_PAGE',
  USER_CLICKED_CHANGE_EMAIL_REGISTER: 'USER_CLICKED_CHANGE_EMAIL_REGISTER',
  USER_CLICKED_VERIFY_EMAIL_REGISTER: 'USER_CLICKED_VERIFY_EMAIL_REGISTER',
  USER_CLICKED_RESEND_CODE: 'USER_CLICKED_RESEND_CODE',
  // login
  USER_CLICKED_LOGIN_BUTTON: 'USER_CLICKED_LOGIN_BUTTON',
  USER_CLICKED_REGISTER_LINK_FROM_LOGIN: 'USER_CLICKED_REGISTER_LINK_FROM_LOGIN',
  USER_VIEWED_LOGIN_PAGE: 'USER_VIEWED_LOGIN_PAGE',
  USER_CLICKED_FORGOT_PASSWORD: 'USER_CLICKED_FORGOT_PASSWORD',
  // register
  USER_CLICKED_REGISTER_BUTTON: 'USER_CLICKED_REGISTER_BUTTON',
  USER_CLICKED_LOGIN_LINK_FROM_REGISTER: 'USER_CLICKED_LOGIN_LINK_FROM_REGISTER',
  USER_VIEWED_REGISTRATION_PAGE: 'USER_VIEWED_REGISTRATION_PAGE',
  // forgot password
  USER_VIEWED_FORGOT_PASSWORD_PAGE: 'USER_VIEWED_FORGOT_PASSWORD_PAGE',
  USER_CLICKED_SEND_CODE_FORGOTPASSWORD: 'USER_CLICKED_SEND_CODE_FORGOTPASSWORD',
  USER_CLICKED_UPDATE_PASSWORD: 'USER_CLICKED_UPDATE_PASSWORD',

  // before you start
  USER_VIEWED_BEFORE_YOU_START_APPLICATION_PAGE: 'USER_VIEWED_BEFORE_YOU_START_APPLICATION_PAGE',
  USER_CLICKED_READY_START_APPLICATION_BUTTON: 'USER_CLICKED_READY_START_APPLICATION_BUTTON',

  // partner services
  USER_VIEWED_SERVICES_PAGE: 'USER_VIEWED_SERVICES_PAGE',
  USER_CLOSED_DEACTIVATE_POPUP: 'USER_CLOSED_DEACTIVATE_POPUP',
  USER_CLICKED_DEACTIVATE_BUTTON: 'USER_CLICKED_DEACTIVATE_BUTTON',
  USER_CLICKED_ADD_A_SERVICE: 'USER_CLICKED_ADD_A_SERVICE',
  USER_OPENED_DEACTIVATE_POPUP: 'USER_OPENED_DEACTIVATE_POPUP',
  USER_COPIED_PUBLIC_PAYMENT_URL: 'USER_COPIED_PUBLIC_PAYMENT_URL',
  USER_CLICKED_CONTINUE_APPLICATION_BUTTON: 'USER_CLICKED_CONTINUE_APPLICATION_BUTTON',
  USER_OPENED_APPLICATION_DROPDOWN: 'USER_OPENED_APPLICATION_DROPDOWN',
  USER_CLOSED_APPLICATION_DROPDOWN: 'USER_CLOSED_APPLICATION_DROPDOWN',
  USER_CLICKED_SERVICES_READ_MORE_LINK: 'USER_CLICKED_SERVICES_READ_MORE_LINK',
  USER_CLICKED_START_ACCEPTING_PAYMENTS_LINK: 'USER_CLICKED_START_ACCEPTING_PAYMENTS_LINK',
  USER_CLICKED_DOWNLOAD_QR_CODE_BUTTON: 'USER_CLICKED_DOWNLOAD_QR_CODE_BUTTON',

  // service-info
  USER_VIEWED_APPLICATION_SERVICE_INFO_PAGE: 'USER_VIEWED_APPLICATION_SERVICE_INFO_PAGE',
  USER_CLICKED_CHANGE_BUSINESS_REGISTRATION_FILE: 'USER_CLICKED_CHANGE_BUSINESS_REGISTRATION_FILE',
  USER_CLICKED_REGULATED_INDUSTRIES_LINK: 'USER_CLICKED_REGULATED_INDUSTRIES_LINK',

  // contact-info
  USER_VIEWED_APPLICATION_CONTACT_INFO_PAGE: 'USER_VIEWED_APPLICATION_CONTACT_INFO_PAGE',
  USER_CLICKED_DELETE_BUSINESS_OWNER_BUTTON: 'USER_CLICKED_DELETE_BUSINESS_OWNER_BUTTON',
  USER_ENTERED_COMBINED_BUSINESS_OWNERSHIP_EXCEEDING_100_PERCENT: 'USER_ENTERED_COMBINED_BUSINESS_OWNERSHIP_EXCEEDING_100_PERCENT',
  USER_CLICKED_ADD_BUSINESS_OWNER_BUTTON: 'USER_CLICKED_ADD_BUSINESS_OWNER_BUTTON',
  USER_CLICKED_CHANGE_VOID_CHEQUE_FILE: 'USER_CLICKED_CHANGE_VOID_CHEQUE_FILE',

  // payment options
  USER_VIEWED_APPLICATION_PAYMENT_OPTIONS_PAGE: 'USER_VIEWED_APPLICATION_PAYMENT_OPTIONS_PAGE',
  USER_CLICKED_CANADA_POST_FEE_TABLE_LINK: 'USER_CLICKED_CANADA_POST_FEE_TABLE_LINK',
  USER_SELECTED_NO_PAYMENT_OPTIONS: 'USER_SELECTED_NO_PAYMENT_OPTIONS',

  // agreement
  USER_VIEWED_APPLICATION_AGREEMENT_PAGE: 'USER_VIEWED_APPLICATION_AGREEMENT_PAGE',
  USER_CLICKED_PRINT_OR_SAVE_PDF: 'USER_CLICKED_PRINT_OR_SAVE_PDF',
  USER_CLICKED_AGREE_TO_TERMS_BUTTON: 'USER_CLICKED_AGREE_TO_TERMS_BUTTON',

  // paysimply preferences
  USER_VIEWED_APPLICATION_PAYSIMPLY_PREFERENCES_PAGE: 'USER_VIEWED_APPLICATION_PAYSIMPLY_PREFERENCES_PAGE',
  USER_CLICKED_SELECT_FILE_BUTTON: 'USER_CLICKED_SELECT_FILE_BUTTON',
  USER_DELETED_UPLOADED_FILE_BUTTON: 'USER_DELETED_UPLOADED_FILE_BUTTON',
  USER_CLICKED_FIELD_HELP_LINK: 'USER_CLICKED_FIELD_HELP_LINK',

  // reports
  USER_VIEWED_REPORTS_PAGE: 'USER_VIEWED_REPORTS_PAGE',
  USER_SELECTED_PARTNER_TO_VIEW_REPORTS: 'USER_SELECTED_PARTNER_TO_VIEW_REPORTS',
  USER_VIEWED_SELECT_PARTNER_PAGE: 'USER_VIEWED_SELECT_PARTNER_PAGE',
  USER_CLICKED_BACK_BUTTON: 'USER_CLICKED_BACK_BUTTON',
  USER_CLICKED_REPORT_TAB: 'USER_CLICKED_REPORT_TAB',
  USER_CLICKED_DATEPICKER_BUTTON: 'USER_CLICKED_DATEPICKER_BUTTON',
  USER_CLICKED_REPORT_SEARCH_BUTTON: 'USER_CLICKED_REPORT_SEARCH_BUTTON',
  REPORT_FULLY_LOADED: 'REPORT_FULLY_LOADED',
  USER_CLICKED_REPORT_EXPORT_CSV: 'USER_CLICKED_REPORT_EXPORT_CSV',
  USER_FILTERED_REPORT_COLUMN: 'USER_FILTERED_REPORT_COLUMN',

  // general
  API_ERROR: 'API_ERROR',
  API_MAINTENANCE_MODE: 'API_MAINTENANCE_MODE',
  API_SUCCESS: 'API_SUCCESS',
  GOOGLE_RECAPTCHA_ERROR: 'GOOGLE_RECAPTCHA_ERROR',
  GOOGLE_RECAPTCHA_CANCEL: 'GOOGLE_RECAPTCHA_CANCEL',
  USER_VIEWED_MAINTENANCE_PAGE: 'USER_VIEWED_MAINTENANCE_PAGE',
  USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON: 'USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON',
  USER_VIEWED_ERROR_PAGE: 'USER_VIEWED_ERROR_PAGE',
  USER_CLICKED_ERROR_PAGE_BUTTON: 'USER_CLICKED_ERROR_PAGE_BUTTON',
  USER_ENTERED_INVALID_VALUE: 'USER_ENTERED_INVALID_VALUE',
  USER_CLICKED_APPLICATION_BACK_BUTTON: 'USER_CLICKED_APPLICATION_BACK_BUTTON',
  USER_CLICKED_APPLICATION_SAVE_BUTTON: 'USER_CLICKED_APPLICATION_SAVE_BUTTON',
  USER_CLICKED_APPLICATION_NEXT_BUTTON: 'USER_CLICKED_APPLICATION_NEXT_BUTTON',
};
