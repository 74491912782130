import { getLanguage, setLanguage, t } from 'react-switch-lang';
import { ExternalLink } from './Link';
import { events, logAmpEvent } from '../utils/Amplitude';
import style from '../styles/Footer.module.scss';
import { LANGUAGES } from '../utils/Constants';
import ReplaceTextWithElement from './ReplaceTextWithElement';
import Button from './Button';
import { useRouter } from './Router';

export default function Footer({ showLanguageSwitcher }) {
  const router = useRouter();
  const ar = router.pathname.split('[lang]');
  const curPath = ar[ar.length - 1];

  function languageToFull() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.EN.label : LANGUAGES.FR.label;
  }

  function languageToCode() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.EN.code : LANGUAGES.FR.code;
  }

  function selectLanguage() {
    const lang = languageToCode();
    if (getLanguage() === lang) return;
    logAmpEvent(events.USER_CHANGED_LANGUAGE, { Language: lang === LANGUAGES.FR.code ? 'French' : 'English' });
    document.documentElement.lang = lang;
    if (router.pathname === '/404') {
      setLanguage(lang);
      router.pushStatic('/404', router.asPath);
    } else {
      router.pushStatic(`/${lang}${curPath}`);
    }
  }

  return (
    <footer id="footer" className={style.footer}>
      <div className={style.footerContentContainer}>
        <div>
          <ExternalLink
            href={t('Link_PaySimply_Partner_Portal_Web_PrivacyPolicy')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: t('Footer_PrivacyPolicy', null, LANGUAGES.EN.code) }}
            text={t('Footer_PrivacyPolicy')}
            aria-label={t('LinkDescription_PrivacyPolicy')}
          />
          {showLanguageSwitcher && (
            <Button
              linkBtn
              text={languageToFull()}
              ariaLabel={t('Language_Switch_To', null, getLanguage())}
              onClick={() => { selectLanguage(); }}
              lang={languageToCode()}
            />
          )}
        </div>
        <div className={style.disclaimerContainer}>
          <p>
            {t('Footer_Copyright_Text1')}
            {' '}
            <ExternalLink
              href={t('Link_PaymentSource')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: t('Footer_CopyRight_PSI_LinkText', null, LANGUAGES.EN.code) }}
              text={t('Footer_CopyRight_PSI_LinkText')}
              aria-label={t('LinkDescription_CopyRight_PSI')}
              className={style.psiLink}
            />
          </p>
          <p>
            <ReplaceTextWithElement
              text={t('Footer_Copyright_Text2')}
              replaceWith={<i>Interac</i>}
              replaceKey="Interac"
            />
          </p>
        </div>
      </div>
    </footer>
  );
}
